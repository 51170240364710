/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";

import Layout from "../components/Layout";

import { ImpressumPageTemplate } from "../templates/impressum-page";

const ImpressumPage = ({ data }) => {
    const html = data.markdownRemark.html;
    return (
        <Layout>
            <ImpressumPageTemplate html={html} />
        </Layout>
    );
};

export default ImpressumPage;

export const pageQuery = graphql`
	query impressumPageQuery {
		markdownRemark(frontmatter: { templateKey: { eq: "impressum-page" } }) {
			html
		}
	}
`;
